<template>
  <div class="content-container">
    <PageTitle :title="$t('RANK_LIST')" class="page-title"/>
    <h2 v-if="rankingData" class="page-section-title section-title">{{ $t('CLASSES_OVERVIEW') }}</h2>

    <CustomTable
      v-if="rankingData"
      :options="options"
      :table-data="rankingData.classes"
      table-class="rankings-table"
      :pagination-data="rankingData"
      :search-function="manageSearch"
      :filter-data="filterData"
      :filter-data-secondary="municipality"
      :search-bar-options="{ placeholder: 'Zoeken op naam klas', searchId: 0 }"
    >
      <template #position="props">
        <div>{{ props.rowData.position }}</div>
      </template>
      <template #positionWithinFilter="props">
        <div>{{ props.rowData.positionWithinFilter }}</div>
      </template>
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <!-- <template #schoolName="props">
        <div>{{ props.rowData.schoolName }}</div>
      </template> -->
      <template #schoolTypeId="props">
        <div>{{ filterData.dropdownOptions[0].options.find(option => option.id === props.rowData.schoolTypeId).name }}</div>
      </template>
      <template #bitsEarned="props">
        <div>{{ props.rowData.bitsEarned }}</div>
      </template>
      <template #rank="props">
        <div>{{ ranks.find(rank => rank.id === props.rowData.rankId).name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="viewPressed(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p><b style="font-weight: 500">{{ classesFound }}</b>  {{ $t('RANKING_LIST.CLASS_TOTAL_SUFFIX') }}</p>
      </template>>
    </CustomTable>

    <template v-if="apiError"></template>
    <p v-if="apiError === 'week_is_upcoming'">
      {{ $t('RANKING_LIST.NO_RANKING_AVAILABLE_PRE_WEEK') }}
    </p>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { computed, ref } from 'vue'
import { getTableOptions, RANKING_TABLE } from '@/utils/helpers/customTable/CustomTableHelper'
import router from '@/router'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { useStore } from 'vuex'
import { GET_RANKING_OVERVIEW_HELPDESK, GET_PROVINCES, GET_MUNICIPALITIES } from '@/store/modules/helpdesk/actions'
import { classRanks } from '@/utils/data/classRanks'

export default {
  name: 'Ranking',
  components: {
    PageTitle,
    CustomTable,
    ActionButton,
  },
  setup() {
    const classesFound = computed(() => 167)
    const store = useStore()
    const apiError = ref('')

    const ranks = classRanks;
    const rankingData = computed(() => store.getters.getRankingHelpdesk)

    const educationOptions = [{ id: 1, name: 'Basisonderwijs' }, { id: 2, name: 'Speciaal (basis)onderwijs' }, { id: 3, name: 'Voortgezet onderwijs' }, { id: 4, name: 'Speciaal voortgezet onderwijs' }]
    const provinces = computed(() => store.getters.getProvinces)
    const municipalities = computed(() => store.getters.getMunicipalities)

    store.dispatch(GET_PROVINCES)
    store.dispatch(GET_MUNICIPALITIES);

    const municipality = computed(() => {
      return {
        placeholder: 'Filter op gemeente',
        singleSelection: false,
        dropdownOptions: [
          {
            name: 'Type gemeente',
            type: 'municipality',
            singleSelection: false,
            options: municipalities.value.map((item, index) => ({ id: item, name: item, type: 'municipality', singleSelection: false}))
          }
        ]
      }
    })

    const filterData = computed(() => {
      return {
        placeholder: 'Filter op categorie',
        singleSelection: false,
        dropdownOptions: [
          {
            name: 'Type onderwijs',
            type: 'schoolTypeId',
            singleSelection: false,
            options: educationOptions.map(e => { return { id: e.id, name: e.name, type: 'schoolTypeId', singleSelection: false } })
          },
          {
            singleSelection: true,
            name: 'Rangschikking',
            type: 'rankId',
            options: ranks.map(r => { return { id: r.id, name: r.name, type: 'rankId', singleSelection: true } })
          },
          {
            singleSelection: true,
            name: 'Provincie',
            type: 'province',
            options: provinces.value.map(province => { return { id: province, name: province, type: 'province', singleSelection: true } })
          }
        ]
      }
    })

    manageSearch({})
    function manageSearch(payload) {
      store.dispatch(GET_RANKING_OVERVIEW_HELPDESK, payload)
        .then((res) => {
          apiError.value = ''
        })
        .catch((e) => {
          // console.log('e.toJSON()', e.toJSON())
          if (e.response && e.response.status === 400 && e.response.data.error === 'week_is_upcoming') {
            apiError.value = e.response.data.error
          }
        })
    }

    const options = computed(() => getTableOptions(RANKING_TABLE))

    function viewPressed(rowData) {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW_INSIGHT, params: { classId: rowData.id } })
    }

    function getPosition(aClass) {
      return rankingData.value.classes.indexOf(aClass) + rankingData.value.limit * (rankingData.value.currentPage - 1) + 1
    }

    return {
      apiError,
      rankingData,
      manageSearch,
      options,
      filterData,
      classesFound,
      viewPressed,
      getPosition,
      ranks,
      municipality,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";



.filter-bar {
  display: flex;
  justify-content: flex-start;

  #class-searchbar {
    margin-right: rem(15);
  }

}

.ranking-header {
  display: flex;
  border-radius: 0.5rem;
  background: rgb(38,110,178);
  background: linear-gradient(90deg, rgba(38,109,177,1) 0%, rgba(185,38,108,1) 100%);
}

.ranking-header-shield-background {
  background: white;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  margin: 0.5rem;
  padding: rem(3) rem(4);
  & > img {
    object-fit: contain;
  }
}

.ranking-header-text {
  color: white;
  justify-self: center;
  align-self: center;
  font-size: 1.2rem;
  margin-left: 0.25rem;
  text-align: center;
  & > p {
    text-align: center;
  }
}

</style>
